<script setup lang="ts">
  import { computed } from 'vue'
  import { SelectHTMLAttributes } from 'vue'

  const props = defineProps<{
    modelValue: SelectHTMLAttributes['value']
    icon?: string
    disabled?: boolean
    label?: string
    required?: boolean
    error?: string[]
    placeholder?: string
  }>()

  const emit = defineEmits<{
    (e: 'update:modelValue', value: typeof props.modelValue): void
    (e: 'change', value: typeof props.modelValue): void
  }>()

  const computedValue = computed({
    set: (value) => {
      emit('update:modelValue', value)
      emit('change', value)
    },
    get: () => props.modelValue,
  })
</script>

<template>
  <div>
    <label v-if="label">
      {{ label }}
      <span v-if="required" class="text-red-600">*</span>
    </label>
    <div class="relative flex items-center">
      <select
        v-model="computedValue"
        :required="required"
        class="form-select form-input cursor-pointer appearance-none pr-8"
        :class="{
          'indent-6': icon,
        }"
        :disabled="disabled"
      >
        <option v-if="placeholder" :value="null" hidden>
          {{ placeholder }}
        </option>
        <slot></slot>
      </select>

      <div
        v-if="icon"
        class="pointer-events-none absolute left-3 z-10 text-gray-500"
      >
        <fa-icon :icon="icon"></fa-icon>
      </div>
      <div class="pointer-events-none absolute right-2 z-10 text-xs">
        <fa-icon icon="chevron-down"></fa-icon>
      </div>
    </div>
    <g-form-error :value="error"></g-form-error>
  </div>
</template>

<style lang="postcss" scoped></style>
