<script setup lang="ts">
  import { computed, ref } from 'vue'

  const emit = defineEmits<{
    (e: 'update:modelValue', data: boolean): void
  }>()

  const props = defineProps<{
    modelValue: boolean
    label: string
    disabled?: boolean
  }>()

  const checked = computed({
    set: (val: boolean) => emit('update:modelValue', val),
    get: () => props.modelValue,
  })

  const inputCheckbox = ref()
</script>

<template>
  <div class="flex items-center space-x-2">
    <input
      ref="inputCheckbox"
      v-model="checked"
      type="checkbox"
      class="accent-primary-default"
      :disabled="disabled"
    />
    <button type="button" @click="inputCheckbox?.click()">
      <label
        class="mb-0"
        :class="
          disabled ? 'cursor-not-allowed text-gray-500' : 'cursor-pointer'
        "
      >
        {{ label }}
      </label>
    </button>
  </div>
</template>
